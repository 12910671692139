html {
  scrollbar-color: var(--bs-gray-600) var(--bs-gray-300);
  scrollbar-width: thin;
}

body {
  line-height: 1.563;
}

.h1, h1, .h2, h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
  margin: 1.563rem 0;
}

.h1:first-child, h1:first-child, .h2:first-child, h2:first-child, .h3:first-child, h3:first-child, .h4:first-child, h4:first-child, .h5:first-child, h5:first-child, .h6:first-child, h6 {
  margin-top: 0;
}

.h1, h1 {
  font-size: 1.25rem;
}

.h2, h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
  font-size: 1rem;
}

p:last-child {
  margin: 0;
}

.section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-top: calc(1.5rem + 88px);
  padding-bottom: 1.5rem;
  display: flex;
}

.section.portfolio {
  min-height: auto;
}

.section.footer {
  min-height: auto;
  padding-top: 1.5rem;
}

.divider {
  background-color: #0000001a;
  width: 100%;
  height: 1rem;
  box-shadow: inset 0 .15rem .25rem #0000001a, inset 0 .15rem .25rem #00000026;
}

.nav-link.active {
  color: var(--bs-warning) !important;
}

.linhaplana {
  letter-spacing: -2px;
}

.btn {
  font-weight: 700;
}

.btn-flex {
  justify-content: center;
  align-self: normal;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.logo {
  max-height: 150px;
}

.avatar {
  max-height: 100px;
}

.scroller {
  scrollbar-color: var(--bs-danger-border-subtle) var(--bs-danger);
  scrollbar-width: thin;
}

.paragraph-link {
  transition: all .15s ease-in-out;
}

.paragraph-link:hover {
  --bs-bg-opacity: 1;
  color: var(--bs-dark) !important;
}

.paragraph-link.link-info:hover, .paragraph-link.link-dark:hover {
  color: var(--bs-light) !important;
}

.gallery .glightbox {
  max-height: 125px;
}

.tooltip {
  --bs-tooltip-bg: var(--bs-dark);
  --bs-tooltip-font-size: 1rem;
  --bs-tooltip-padding-y: .5rem;
}

.tooltip-success {
  --bs-tooltip-color: var(--bs-success);
}

.bg-gradient-primary {
  background-image: linear-gradient(310deg, var(--bs-primary), var(--bs-primary-text-emphasis));
}

.bg-gradient-secondary {
  background-image: linear-gradient(310deg, var(--bs-secondary), var(--bs-secondary-text-emphasis));
}

.bg-gradient-success {
  background-image: linear-gradient(310deg, var(--bs-success), var(--bs-success-text-emphasis));
}

.bg-gradient-info {
  background-image: linear-gradient(310deg, var(--bs-info), var(--bs-info-text-emphasis));
}

.bg-gradient-warning {
  background-image: linear-gradient(310deg, var(--bs-warning), var(--bs-warning-text-emphasis));
}

.bg-gradient-danger {
  background-image: linear-gradient(310deg, var(--bs-danger), var(--bs-danger-text-emphasis));
}

.bg-gradient-light {
  background-image: linear-gradient(310deg, var(--bs-light), var(--bs-light-text-emphasis));
}

.bg-gradient-dark {
  background-image: linear-gradient(310deg, var(--bs-dark-text-emphasis), var(--bs-dark));
}

.text-gradient {
  -webkit-text-fill-color: transparent;
  z-index: 1;
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
}

.text-gradient.text-primary {
  background-image: linear-gradient(310deg, var(--bs-primary), var(--bs-primary-border-subtle));
}

.text-gradient.text-primary-emphasis {
  background-image: linear-gradient(310deg, var(--bs-primary-text-emphasis), var(--bs-primary));
}

.text-gradient.text-secondary {
  background-image: linear-gradient(310deg, var(--bs-secondary), var(--bs-secondary-border-subtle));
}

.text-gradient.text-secondary-emphasis {
  background-image: linear-gradient(310deg, var(--bs-secondary-text-emphasis), var(--bs-secondary));
}

.text-gradient.text-success {
  background-image: linear-gradient(310deg, var(--bs-success), var(--bs-success-border-subtle));
}

.text-gradient.text-success-emphasis {
  background-image: linear-gradient(310deg, var(--bs-success-text-emphasis), var(--bs-success));
}

.text-gradient.text-info {
  background-image: linear-gradient(310deg, var(--bs-info), var(--bs-info-border-subtle));
}

.text-gradient.text-info-emphasis {
  background-image: linear-gradient(310deg, var(--bs-info-text-emphasis), var(--bs-info));
}

.text-gradient.text-warning {
  background-image: linear-gradient(310deg, var(--bs-warning), var(--bs-warning-border-subtle));
}

.text-gradient.text-warning-emphasis {
  background-image: linear-gradient(310deg, var(--bs-warning-text-emphasis), var(--bs-warning));
}

.text-gradient.text-danger {
  background-image: linear-gradient(310deg, var(--bs-danger), var(--bs-danger-border-subtle));
}

.text-gradient.text-danger-emphasis {
  background-image: linear-gradient(310deg, var(--bs-danger-text-emphasis), var(--bs-danger));
}

.text-gradient.text-light {
  background-image: linear-gradient(310deg, var(--bs-light), var(--bs-light-border-subtle));
}

.text-gradient.text-light-emphasis {
  background-image: linear-gradient(310deg, var(--bs-light-text-emphasis), var(--bs-light));
}

.text-gradient.text-dark {
  background-image: linear-gradient(310deg, var(--bs-dark), var(--bs-dark-border-subtle));
}

.text-gradient.text-dark-emphasis {
  background-image: linear-gradient(310deg, var(--bs-dark-text-emphasis), var(--bs-dark));
}

@media (width >= 576px) {
  .h1, h1 {
    font-size: 1.563rem;
  }

  .h2, h2 {
    font-size: 1.25rem;
  }

  .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
    font-size: 1rem;
  }

  .section {
    padding-top: calc(3rem + 64px);
    padding-bottom: 3rem;
  }

  .section.footer {
    min-height: 25vh;
    padding-top: 3rem;
  }
}

@media (width >= 768px) {
  .h1, h1 {
    font-size: 1.953rem;
  }

  .h2, h2 {
    font-size: 1.563rem;
  }

  .h3, h3 {
    font-size: 1.25rem;
  }

  .h4, h4, .h5, h5, .h6, h6 {
    font-size: 1rem;
  }

  .avatar {
    max-height: 150px;
  }
}

@media (width >= 992px) {
  .h1, h1 {
    font-size: 2.441rem;
  }

  .h2, h2 {
    font-size: 1.953rem;
  }

  .h3, h3 {
    font-size: 1.563rem;
  }

  .h4, h4 {
    font-size: 1.25rem;
  }

  .h5, h5, .h6, h6 {
    font-size: 1rem;
  }
}

@media (width >= 1200px) {
  .h1, h1 {
    font-size: 3.052rem;
  }

  .h2, h2 {
    font-size: 2.441rem;
  }

  .h3, h3 {
    font-size: 1.953rem;
  }

  .h4, h4 {
    font-size: 1.563rem;
  }

  .h5, h5 {
    font-size: 1.25rem;
  }

  .h6, h6 {
    font-size: 1rem;
  }
}

@media (width >= 1400px) {
  .h1, h1 {
    font-size: 3.815rem;
  }

  .h2, h2 {
    font-size: 3.052rem;
  }

  .h3, h3 {
    font-size: 2.441rem;
  }

  .h4, h4 {
    font-size: 1.953rem;
  }

  .h5, h5 {
    font-size: 1.563rem;
  }

  .h6, h6 {
    font-size: 1.25rem;
  }
}

/*# sourceMappingURL=index.7dc07477.css.map */
