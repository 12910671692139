/* ==========================================================================
DEFAULT
========================================================================== */
html {
  scrollbar-color: var(--bs-gray-600) var(--bs-gray-300);
  scrollbar-width: thin;
}

body {
  line-height: 1.563;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  margin: 1.563rem 0 1.563rem;
}

.h1:first-child,
h1:first-child,
.h2:first-child,
h2:first-child,
.h3:first-child,
h3:first-child,
.h4:first-child,
h4:first-child,
.h5:first-child,
h5:first-child,
.h6:first-child,
h6 {
  margin-top: 0;
}

.h1,
h1 {
  font-size: 1.25rem;
}

.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-size: 1rem;
}

p:last-child {
  margin: 0;
}

/* ==========================================================================
INDEX
========================================================================== */
.section {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding-bottom: 1.5rem;
  padding-top: calc(1.5rem + 88px);

  &.portfolio {
    min-height: auto;
  }

  &.footer {
    min-height: auto;
    padding-top: 1.5rem;
  }
}

.divider {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0.15rem 0.25rem rgba(0, 0, 0, 0.1), inset 0 0.15rem 0.25rem rgba(0, 0, 0, 0.15);
  height: 1rem;
  width: 100%;
}

.nav-link {
  &.active {
    color: var(--bs-warning) !important;
  }
}

.linhaplana {
  letter-spacing: -2px;
}

.btn {
  font-weight: 700;
}

.btn-flex {
  align-items: center;
  align-self: normal;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

.logo {
  max-height: 150px;
}

.avatar {
  max-height: 100px;
}

.scroller {
  scrollbar-color: var(--bs-danger-border-subtle) var(--bs-danger);
  scrollbar-width: thin;
}

.paragraph-link {
  transition: all 0.15s ease-in-out;

  &:hover {
    --bs-bg-opacity: 1;
    color: var(--bs-dark) !important;
  }

  &.link-info:hover,
  &.link-dark:hover {
    color: var(--bs-light) !important;
  }
}

.gallery .glightbox {
  max-height: 125px;
}

/* ==========================================================================
LAYOUT
========================================================================== */
.tooltip {
  --bs-tooltip-bg: var(--bs-dark);
  --bs-tooltip-font-size: 1rem;
  --bs-tooltip-padding-y: 0.5rem;
}

.tooltip-success {
  --bs-tooltip-color: var(--bs-success);
}

.bg-gradient-primary {
  background-image: linear-gradient(310deg, var(--bs-primary), var(--bs-primary-text-emphasis));
}

.bg-gradient-secondary {
  background-image: linear-gradient(310deg, var(--bs-secondary), var(--bs-secondary-text-emphasis));
}

.bg-gradient-success {
  background-image: linear-gradient(310deg, var(--bs-success), var(--bs-success-text-emphasis));
}

.bg-gradient-info {
  background-image: linear-gradient(310deg, var(--bs-info), var(--bs-info-text-emphasis));
}

.bg-gradient-warning {
  background-image: linear-gradient(310deg, var(--bs-warning), var(--bs-warning-text-emphasis));
}

.bg-gradient-danger {
  background-image: linear-gradient(310deg, var(--bs-danger), var(--bs-danger-text-emphasis));
}

.bg-gradient-light {
  background-image: linear-gradient(310deg, var(--bs-light), var(--bs-light-text-emphasis));
}

.bg-gradient-dark {
  background-image: linear-gradient(310deg, var(--bs-dark-text-emphasis), var(--bs-dark));
}

.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  position: relative;
  -webkit-text-fill-color: transparent;
  z-index: 1;

  &.text-primary {
    background-image: linear-gradient(310deg, var(--bs-primary), var(--bs-primary-border-subtle));
  }

  &.text-primary-emphasis {
    background-image: linear-gradient(310deg, var(--bs-primary-text-emphasis), var(--bs-primary));
  }

  &.text-secondary {
    background-image: linear-gradient(310deg, var(--bs-secondary), var(--bs-secondary-border-subtle));
  }

  &.text-secondary-emphasis {
    background-image: linear-gradient(310deg, var(--bs-secondary-text-emphasis), var(--bs-secondary));
  }

  &.text-success {
    background-image: linear-gradient(310deg, var(--bs-success), var(--bs-success-border-subtle));
  }

  &.text-success-emphasis {
    background-image: linear-gradient(310deg, var(--bs-success-text-emphasis), var(--bs-success));
  }

  &.text-info {
    background-image: linear-gradient(310deg, var(--bs-info), var(--bs-info-border-subtle));
  }

  &.text-info-emphasis {
    background-image: linear-gradient(310deg, var(--bs-info-text-emphasis), var(--bs-info));
  }

  &.text-warning {
    background-image: linear-gradient(310deg, var(--bs-warning), var(--bs-warning-border-subtle));
  }

  &.text-warning-emphasis {
    background-image: linear-gradient(310deg, var(--bs-warning-text-emphasis), var(--bs-warning));
  }

  &.text-danger {
    background-image: linear-gradient(310deg, var(--bs-danger), var(--bs-danger-border-subtle));
  }

  &.text-danger-emphasis {
    background-image: linear-gradient(310deg, var(--bs-danger-text-emphasis), var(--bs-danger));
  }

  &.text-light {
    background-image: linear-gradient(310deg, var(--bs-light), var(--bs-light-border-subtle));
  }

  &.text-light-emphasis {
    background-image: linear-gradient(310deg, var(--bs-light-text-emphasis), var(--bs-light));
  }

  &.text-dark {
    background-image: linear-gradient(310deg, var(--bs-dark), var(--bs-dark-border-subtle));
  }

  &.text-dark-emphasis {
    background-image: linear-gradient(310deg, var(--bs-dark-text-emphasis), var(--bs-dark));
  }
}

/* ==========================================================================
MEDIA QUERIES
========================================================================== */
@media (min-width: 576px) {
  .h1,
  h1 {
    font-size: 1.563rem;
  }

  .h2,
  h2 {
    font-size: 1.25rem;
  }

  .h3,
  h3,
  .h4,
  h4,
  .h5,
  h5,
  .h6,
  h6 {
    font-size: 1rem;
  }

  .section {
    padding-bottom: 3rem;
    padding-top: calc(3rem + 64px);

    &.footer {
      min-height: 25vh;
      padding-top: 3rem;
    }
  }
}

@media (min-width: 768px) {
  .h1,
  h1 {
    font-size: 1.953rem;
  }

  .h2,
  h2 {
    font-size: 1.563rem;
  }

  .h3,
  h3 {
    font-size: 1.25rem;
  }

  .h4,
  h4,
  .h5,
  h5,
  .h6,
  h6 {
    font-size: 1rem;
  }

  .avatar {
    max-height: 150px;
  }
}

@media (min-width: 992px) {
  .h1,
  h1 {
    font-size: 2.441rem;
  }

  .h2,
  h2 {
    font-size: 1.953rem;
  }

  .h3,
  h3 {
    font-size: 1.563rem;
  }

  .h4,
  h4 {
    font-size: 1.25rem;
  }

  .h5,
  h5,
  .h6,
  h6 {
    font-size: 1rem;
  }
}

@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 3.052rem;
  }

  .h2,
  h2 {
    font-size: 2.441rem;
  }

  .h3,
  h3 {
    font-size: 1.953rem;
  }

  .h4,
  h4 {
    font-size: 1.563rem;
  }

  .h5,
  h5 {
    font-size: 1.25rem;
  }

  .h6,
  h6 {
    font-size: 1rem;
  }
}

@media (min-width: 1400px) {
  .h1,
  h1 {
    font-size: 3.815rem;
  }

  .h2,
  h2 {
    font-size: 3.052rem;
  }

  .h3,
  h3 {
    font-size: 2.441rem;
  }

  .h4,
  h4 {
    font-size: 1.953rem;
  }

  .h5,
  h5 {
    font-size: 1.563rem;
  }

  .h6,
  h6 {
    font-size: 1.25rem;
  }
}
